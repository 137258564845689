import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider, { useConfigProvider } from '@/context/ConfigProvider';
import withTheme from '@/theme';
import '@/lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
import UserProvider from '@/context/UserProvider';
import dynamic from 'next/dynamic';
import Head from 'next/head';

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/ins-theme.less');

const Login = dynamic(() => import('@/components/Login'), { ssr: false });
const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
  ssr: false,
});
// const LoginResult = dynamic(() => import('@/components/LoginResult'), {
//   ssr: true,
// });
const ArticleBottomAlert = dynamic(() => import('@/components/Common/ArticleBottomAlert'), {
  ssr: false,
});

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const { isShowArticleBottomAlert } = useConfigProvider();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <AntdApp>
      <ConfigProvider>
        <UserProvider>
          <Head>
            <meta
              name='viewport'
              content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
            />
          </Head>

          {getLayout(<Component {...pageProps} />)}
          <Login />
          {/* <LoginResult /> */}
          <ArticleBottomAlert />
          <CookieConsentContent />
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}

export default appWithTranslation(App);
